#banner {
  background-image: radial-gradient(circle,$alabaster,$light-grey);
  // background-image: radial-gradient(circle,#f6f6f6,#ececec);
}

.banner-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: 42px;
  // min-height: 440px;
}

.banner-button-next,
.banner-button-prev {
  display: none;
  @include mappy-bp(med) {
    display: block;
  }
}
.banner-button-prev { left:0}
.banner-button-next { right:0}

.swiper-pagination-bullet-active {  background: $brand-color;}

.banner__card {
  // height: 400px;
  background-size: contain;
  background-position: right center;
  background-repeat: no-repeat;
  padding: 0;
  @include mappy-bp(med) {
    padding: 2em 3em;
  }

}
// @include breakpoint($bp-tablet) {
//   // .banner__card {	background-color: $cream; }
//   .banner__card.etheramed {
//     background-image: url('/img/banner/etheramed.jpg');
//   }
//
//   .banner__card.hilterapie {
//     background-image: url('/img/banner/1.jpg');
//   }
//
//   .banner__card.ultrazvuk {
//     background-image: url('/img/banner/ultrazvuk.jpg');
//   }
//
//   .banner__card.fyzioterapie {
//     background-image: url('/img/banner/2.jpg');
//   }
//
//   .banner__card.elektrolecba {
//     background-image: url('/img/banner/3.jpg');
//   }
// }
