.slider {
  overflow-y: hidden;
  max-height: 500px;
  /* approximate max height */
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slider.closed {
  max-height: 0;
}
