#contact {
  color: $alabaster;
  // background-color: $lonestar;
  background-color: darken($brand-color, 10%);
  > div {
    @extend .clearfix;
  }

  h4 { color: $alabaster;}

  a {
    color: $alabaster;
    text-decoration: underline
  }

  address {
    @include mappy-bp(med) {
       @include span(5 of 12);
    }
  }

  #map {
    @include mappy-bp(med) {
      @include span(7 of 12 last);
    }
  }

  #map-container {
    height: 250px;
    @include mappy-bp(med) {
      height: 500px;
    }
  }

  .contact-form {
    // padding: 2em 0;
    @include mappy-bp(med) {
       @include span(5 of 12);
    }
  }

  .openning-hours p { margin-left: 1rem;}

}
