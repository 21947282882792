.loader {
  display: flex;
  justify-content: center;
  align-items: center;

  .loading {
    margin: 50px;
    height: 28px;
    width: 28px;
    animation: rotate 0.8s infinite linear;
    border: 8px solid #fff;
    border-right-color: transparent;
    border-radius: 50%;
  }
  
  @keyframes rotate {
    0%    { transform: rotate(0deg); }
    100%  { transform: rotate(360deg); }
  }

}
