/*
* All related things to handling navigation on mobile and desktop + see _header.scss as well
*/
nav {
  @include mappy-bp(large) {
    @include span(7 last);
    padding-top: 0.5em; // magic number tio align
  }

  ul {
    margin: 0;
    list-style: none;
    max-width: none; // reset from _typography
  }

  > ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    align-items: center;
    max-height: 0;
    width: 100%;
    overflow: hidden;
    transition: max-height 0.3s ease;
    z-index: 10;

    @include mappy-bp(large) {
      float: right;
      max-height: inherit;
      flex-direction: row;
      overflow: inherit;
      // border-bottom: none;
    }
  }

  &.is-open > ul {
    border-bottom: 1px solid $brand-color;
  }


  &.is-open ul {
    align-items: flex-end;
    max-height: 35em;
  }

  #menu > li {
    list-style: none;
    position: relative;
    text-align: right;
    width: 100%;

    @include mappy-bp(large) {
      flex-basis: auto;
      text-align: right;
      width: auto;

      &:not(:last-child) {
        padding-right: 0.75em;
      }

      &:hover {
        background-color: $light-grey;
        border-top-left-radius: $base-radius;
        border-top-right-radius: $base-radius;
      }

      &:hover a + div {
        max-height: 20rem;
        padding: 0.5em 0.5em 1em 0.5em;
      }
    }

    & a.is-open + div { max-height: 20rem }

    a {
      display: block;
      color: $lonestar;

      @include on-event {
        text-decoration: none;
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 100%;
        top: 75%;
        height: 3px;
        background: $brand-color;
        transition-property: left, right;
        transition-duration: 0.3s;
        transition-timing-function: ease-out;
        transition-delay: 0.1s;
      }

      @include mappy-bp(large) {
        padding: 0 0.5em;
        &:not(.toggler):hover:after {
          left: 0;
          right: 0;
        }
      }

    }

    a + div {
      overflow: hidden;
      background-color: $alabaster;
      max-height: 0;
      transition: max-height 0.3s ease;

      @include mappy-bp(large) {
        position: absolute;
        top: 3em;
        min-width: 250px;
        text-align: left;
        background-color: $light-grey;
        border-bottom-left-radius: $base-radius;
        border-bottom-right-radius: $base-radius;
        // padding: 0.5em 0.5em 1em 0.5em;
      }
      @extend .clearfix;
      min-width: 200px;

      a {
        line-height: 2*$baseline-height*1em;
        // padding: 0.25em 0.5em;
        position: relative;
        @include ms-respond(font-size, 0);
        &:after {
          top: 90%;
          height: 2px;
        }
      }
    }

    > a {
      text-transform: uppercase;
      // font-size: 95%;

      &.active:after {
        left: 0;
        right: 10;
        height: 5px;
        background: $lonestar;
      }
    }

    &:last-child a + div { right: 0}
  } // #menu > li

  li + li {
    margin-top: initial;
  }
}
// styling of menu button

.mobile-nav-toggle {
  position: absolute;
  right: 1.5em;
  top: 0.8em;
  color: $mine-shaft;
  background: $brand-color;
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  text-indent: -1000em;
  text-align: start; //counter issue with text-align right from a links
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2230%22%20height%3D%2214%22%20viewBox%3D%22-6.985%200%2030%2014%22%20enable-background%3D%22new%20-6.985%200%2030%2014%22%3E%3Cpath%20d%3D%22M.218%2010.724H-1.26V5.988L-2.7%208.853h-1.365L-5.51%205.988v4.736h-1.476v-7.71h1.42l2.18%204.406%202.18-4.405H.218v7.71zm1.686-7.71H7.3v1.278H3.447v1.884H6.97v1.288H3.446v1.98h3.866v1.28H1.904v-7.71zm6.8%200h1.464l3.36%205.134V3.015h1.507v7.71h-1.463l-3.36-5.132v5.13H8.705V3.016zM16.67%207.95V3.015h1.53v5c0%201.003.65%201.585%201.643%201.585.99%200%201.63-.582%201.63-1.586v-5h1.542V7.95c0%201.74-1.178%202.93-3.172%202.93S16.67%209.688%2016.67%207.948z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E');
  background-position: 50% 50%;
  background-repeat: no-repeat;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 16px;
  transition: all 250ms linear 0.284738ms;
  @include when-inside(".is-open") {
    background-color: $lonestar;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%229.938%22%20height%3D%229.913%22%20viewBox%3D%220%200%209.938%209.913%22%3E%3Cpath%20fill%3D%22%23fff%22%20d%3D%22M3.89%204.968L0%201.08%201.104%200l3.864%203.888L8.858%200l1.08%201.08-3.89%203.888%203.89%203.865-1.08%201.08-3.89-3.866-3.864%203.866L0%208.833l3.89-3.865z%22%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat;
  }
  @include mappy-bp(large) {
    display: none;
  }
}
