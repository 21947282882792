// -----------------------------------------------------------------------------
// This file contains all settings related to layout setup
// -----------------------------------------------------------------------------
@import './bower_components/susy/sass/susy';


/* Changing Susy default global settings */
$susy: (
  /* Tells Susy to use 12 columns */
  columns: 12,
  /* Tells Susy that the container has a max-width of 1120px */
  container: 1120px, // 70rem
  /* Tells Susy to use border-box */
  global-box-sizing: border-box
);



.page {
  @include container;
  overflow:auto; // to prevent collapsed margin of the first element
  padding-left: 1em;
  padding-right: 1em;
}
