// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

header {
  // background-color: $menu-background;
  // font-family: 'Roboto';
  @extend .clearfix;
  min-height: 4rem;
  line-height: 4rem;

  .logo {
    display: block;
    width: 190px;
    line-height: 4rem;
    // line-height: 50px;
    margin: 0;
    // margin-left: 1em;
    @include mappy-bp(med) {
      width: 250px;
      display: inline-block;
      // float: left;

      a {
        vertical-align: middle;
      }
    }
    @include mappy-query(tablet) {
      width: 190px;
    }
  }

  .logo img {
    height: auto;
    width: 100%;
    @include mappy-bp(med) {
      height: 35px;
    }
  }
}
