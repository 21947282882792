#testimonials {
  background-image: radial-gradient(circle,$brand-color,$brand-color-darker);
  // background-image: radial-gradient(circle,#f43257,#d1083f);
  color: $alabaster;
  font-smoothing: antialiased;
  font-weight: 300;
  padding-left: 0;
  padding-right: 0;
  min-height: initial; // overide section min height from site.scss

  .testimonial {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1em;
    text-align: center;
    font-style: italic;

    max-width: 22.5em;

    @media (min-width: 47em) {
      max-width: 37em;
    }

    // @media (min-width: 1200px) {
    //   max-width: 37.5em;
    // }

    @include mappy-bp(med) {
      padding: 0 56px;
      background-image: url('../img/quote-left.png'),url('../img/quote-right.png');
      background-size: 38px 28px;
      background-position: left 40%,right 40%;
      background-repeat: no-repeat;
    }

    p {

    }
  }
}
