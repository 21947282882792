
table {
    // border: 1px solid #ccc;
    width: 100%;
    margin:0;
    padding:0;
    border-collapse: collapse;
    border-spacing: 0;
}

table tr {
  border: 1px solid #ddd;
  padding: 5px;
}
thead tr { border: none;}
table th, table td {
  padding: 10px;
}

table th {
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1px;
  &:first-child { text-align: left;}
}

.pricing-table {
  .price {text-align: right}
  h3 { color: $lonestar;}
  ul { padding-left: 1em;}
  ul li {
    list-style-type: decimal;
    list-style-position: initial;
    color: $dusty-gray;
    font-size: small;
    font-weight: 300;
  }
}

@media screen and (max-width: 600px) {

  table {
    border: 0;
  }

  table thead {
    display: none;
  }

  table tr {
    margin-bottom: 10px;
    display: block;
    padding: 0;
    border-bottom: 2px solid #ddd;
  }

  table td {
    display: block;
    text-align: right;
    font-size: 13px;
    border-bottom: 1px dotted #ccc;
    &:first-of-type {
      text-align: left;
      background: linear-gradient(to bottom, rgba(243,243,243,1) 0%,rgba(236,236,237,1) 100%);
      border-top:1px solid #fff;
      border-bottom: 1px solid #d4d4d4;
      // max-width: 50vw;
    }
    &:empty { display: none;}
  }

  table td:last-child {
    border-bottom: 0;
  }

  table td:before {
    content: attr(data-label);
    float: left;
    text-transform: uppercase;
    font-weight: bold;
  }
}
