.form-control {
  padding: .375em .75em;
  border: 1px solid $light-grey;
  border-radius: $base-radius;
  margin-bottom: 0.75*$baseline-height*1rem;
  width: 100%;
}

input {
  line-height: $baseline-height;
}
