// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------
.homepage {
  #zazemi {
    background-color: $dark-grey;
    h2,h3 {color: $alabaster;}
  }

  .centered {
    margin-left: auto;
    margin-right: auto;
  }

  .info {
    @include container;
    @extend .clearfix;

    .image {
      margin-top: 1.4em;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      min-height: 150px;
    }
    .pain { background-image: url('../svg/pain.svg') }
    .nopain { background-image: url('../svg/nopain.svg') }
    @include mappy-bp(med) {
      > div:last-child { @include last;}
      .image {min-height: 220px;}
      .text { @include span(8); }
      .nopain, .pain { @include span(4); }
      // .nopain { @include push(8 last);}
      // .nopain + .text { @include pull(4);}
    }
  }
}
