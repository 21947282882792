.features {
  // display: flex;
  // justify-content: space-around;
  // flex-wrap: wrap;
  @include container;
}
.feature {
  height: 300px;
  // flex-basis: 13em;
  position: relative;
  margin: gutter(12);
  margin-bottom: gutter(3);
  // margin-bottom: gutter(12);
  border: 1px solid $dusty-gray;
  border-radius: 0.25rem;
  background-color: $light-grey;
  background-repeat: no-repeat;
  background-position: center 20px;
  background-size: 6em;

  h4 {
    @include ms-respond(font-size, 2);
    background-color: rgba(0,0,0, 0.3);
    margin: 0;
    text-align: center;
    color: $alabaster;
    text-shadow: 2px 2px 2px rgba(150, 150, 150, 1);
  }
  // color: $font-color;
  @include mappy-query(tablet) {
     margin: 0;
     @include nested(6){
      @include span(3);
      margin-bottom: gutter();

      &:nth-of-type(2n) {
        @include last;
      }
    }
  }
  @include mappy-bp(large) {
    margin: 0;
     @include nested(12){
      @include span(3);
      margin-bottom: gutter();

      &:nth-of-type(4n) {
        @include last;
      }
    }
  }
  .btn {
    margin-top: 0.5em;
    display: block;
  }
}

.feature > div {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  font-size: 14px;
  div {
    background-color: rgba(0,0,0, 0.1);
    padding: 1em;
    text-align: center;
  }
  // color: $cream;
}


.feature.akutni { background-image: url('../svg/akutni.svg');}
.feature.chronicke { background-image: url('../svg/chronicke.svg');}
.feature.sportovci { background-image: url('../svg/sport.svg');}
.feature.deti {
  background-image: url('../svg/deti.svg');
  background-size: 5em;
}
