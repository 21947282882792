.swiper-container {
  width: 100%;
  height: 300px;
  background: $dark-grey;
  margin-left: auto;
  margin-right: auto;
}
.swiper-container.gallery-certs {
  background-color: inherit;
  max-width: 22.5em;
  height: 50vh;
  min-height: 500px;
  margin-left: 0;

  @media (min-width: 47em) {
    max-width: 33em;
  }

  @media (min-width: 1200px) {
    max-width: 37.5em;
  }


}

.swiper-wrapper {
	position: relative;
}

.swiper-slide {
	// text-align: center;
	font-size: 18px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
	// background: #000;
}

.swiper-slide img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  left: 50%;
  top: 50%;
}

.swiper-button-next,
.swiper-button-prev {
	height: 2em;
}


.gallery-top {
  height: 250px;
  @include mappy-bp(large) {
    height: 350px;
  }
  width: 100%;
}
.gallery-thumbs {
  height: 100px;
  box-sizing: border-box;
  padding: 10px 0;
  display: none;
  @include mappy-bp(large) {	display: block; }
}

.gallery-thumbs .swiper-slide {
    width: 10%;
    height: 100%;
    opacity: 0.4;
}
.gallery-thumbs .swiper-slide-active {
    opacity: 1;
}
