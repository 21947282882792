
section {
  @extend .clearfix;
  // min-height: 30vh;
  padding: $baseline-height * 1em;
  overflow: auto;
  overflow: overlay;
  &:first-of-type {
    padding-top: 0;
    h1 { margin-top: 0.85rem;}
   }
}
