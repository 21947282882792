// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------


// line-height base for typography
$baseline-height: 1.4;


// colors
$alabaster: rgb(250, 250, 250);
$lonestar: rgb(102, 0, 0);
$hokey-pokey: rgb(204, 153, 51);
$dusty-gray: rgb(153, 153, 153);
$mine-shaft: rgb(58, 58, 58);
$persian-red: rgb(211, 47, 47);
$carnation: rgb(241, 78, 85);

$brand-primary: $lonestar;
$brand-secondary: $persian-red;


/// Regular font family
/// @type List
$text-font-stack: 'Open Sans', 'Roboto', 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;
$text-font-menu: 'Lato', 'Open Sans', 'Roboto', sans-serif;
/// Code (monospace) font family
/// @type List
$code-font-stack: 'Courier New', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Monaco', monospace !default;


/// Copy text color
/// @type Color
$text-color: $mine-shaft !default;
$text-color-headings: $mine-shaft;

/// Main brand color
/// @type Color
// $brand-color: rgb(229, 0, 80) !default;
$brand-color: rgb(229, 0, 80) !default;
$brand-color-darker: darken($brand-color, 5%);

/// Light grey
/// @type Color
$light-grey: rgb(237, 237, 237) !default;

/// Medium grey
/// @type Color
$mid-grey: rgb(153, 153, 153) !default;

/// Dark grey
/// @type Color
$dark-grey: rgb(68, 68, 68) !default;



/// Container's maximum width
/// @type Length
$max-width: 1180px !default;




/* ===== == = === Base Styles === = == ===== */
/* ===== == = === 20em (320px) === = == ===== */
/* ===== == = === 30em (480px) === = == ===== */
/* ===== == = === 37.5em (600px) === = == ===== */
/* ===== == = === 48em (768px) === = == ===== */
/* ===== == = === 56.25em (900px) === = == ===== */
/* ===== == = === 62em (992px) === = == ===== */
/* ===== == = === 68.75em (1100px) === = == ===== */
/* ===== == = === 81.25em (1300px) === = == ===== */



/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
  'small'     : 320px,  // 20rem
  'med'       : 640px,  // 40rem
  'large'     : 920px,  // 57.5rem
  'wide'      : 1200px, // 75rem,
  'mega'      : 1400px, // 87.5rem
) !default;

// https://github.com/zellwk/mappy-breakpoints
// used for proper media queries
@import "./bower_components/mappy-breakpoints/mappy-breakpoints";

$mappy-queries: (
  phone: mappy-bp(h max-height small), // media all and (max-height: 29.9375em)
  tablet: mappy-bp(med large), // @media all and (min-width 30em) and (max-width: 49.375em)
  desktop: mappy-bp(wide), // media all and (min-width: 60em) and (max-width: 74.9375em)
  change-type: mappy-bp(small, $type: screen) // media screen and (min-width: 30em)
);

/* use like this */
// @include mappy-bp(max-width small) { background-color: $dusty-gray;}
// @include mappy-query(tablet) { background-color: $hokey-pokey;}
// @include mappy-query(desktop) { background-color: $brand-color;}


/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: '/assets/' !default;


$base-radius: 3px;
