.objednat {
  width: 80%;
  max-width: 40em;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
  padding-left: 3em;
  background-color: darken($light-grey, 5%);
  background-image: url('../svg/telefon.svg');
  background-repeat: no-repeat;
  background-position: 1em center;
  background-size: 3em;
  border-radius: $base-radius*2;
  text-align: center;

  h4 {
    @include ms-respond(font-size, 2);
    margin:0;
    color: $text-color;
  }

  a {
    @include ms-respond(font-size, 2);
    text-decoration: none;
    color: $carnation;
    font-weight: bold;
    @include on-event {
      text-decoration: underline;;
    }
  }
}
