// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

footer {
  min-height: 100px;
  padding: 1em 1.4em;
  background-color: $mine-shaft;
  color: $light-grey;
  font-size: 85%;
  // text-align: center;
  @include mappy-bp(med){
    text-align: right;
    font-size: 75%;
  }
  a {
    display: block;
    padding-left: 0.75em;
    line-height: 1.5;
    color: $mid-grey;
  }
  span {line-height: 2em*$baseline-height}

  h4,h5,h6 { color: $light-grey}
  h4 {margin-bottom: 0.75em}

  .links {
    margin-bottom: 1em;
    text-align: left;
    @include mappy-bp(med){
      @include span(4 last);
    }
    @include mappy-bp(large) {
      @include span(3);
    }
  }
}
